/* Application style sheet */

/*.main .content {
	max-width: 1080px;
	margin: 0em auto 2em;
	padding: 0em 2em;
}
*/

/*body {
  color: white;  
  height: 100%;
  background: rgba(101,105,159,1);
  background: -moz-linear-gradient(-45deg, rgba(101,105,159,1) 0%, rgba(53,56,102,1) 71%, rgba(53,56,102,1) 100%);
  background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(101,105,159,1)), color-stop(71%, rgba(53,56,102,1)), color-stop(100%, rgba(53,56,102,1)));
  background: -webkit-linear-gradient(-45deg, rgba(101,105,159,1) 0%, rgba(53,56,102,1) 71%, rgba(53,56,102,1) 100%);
  background: -o-linear-gradient(-45deg, rgba(101,105,159,1) 0%, rgba(53,56,102,1) 71%, rgba(53,56,102,1) 100%);
  background: -ms-linear-gradient(-45deg, rgba(101,105,159,1) 0%, rgba(53,56,102,1) 71%, rgba(53,56,102,1) 100%);
  background: linear-gradient(135deg, rgba(101,105,159,1) 0%, rgba(53,56,102,1) 71%, rgba(53,56,102,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#65699f', endColorstr='#353866', GradientType=1 );
}
*/

 /* don't do scrollbars */
/*html, body, #root {
  overflow: hidden;
}
*/

.fc th {
  border-color: white;
  height: 40px;
  vertical-align: middle;  
  font-weight: 400;
}

.fc .fc-timeGridWeek-view td:first-child.fc-widget-content{
  border-width: 0;
}

.fc-axis .fc-time .fc-widget-content {
  /*border-width: 0;  */
}
